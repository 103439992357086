import React 			from "react"
import Layout			from "../components/layout"

const Impressum  = ({data}) => (
	<Layout head_gif = "impressum_n_bhb.png">
  	<h2 align="center">Impressum und Haftungsausschluß</h2>
		<div className="row">
			<p>
			 <b>Haftung für Inhalte</b><br />
			 Die Inhalte aller Seiten wurden mit größter Gewissenhaftigkeit erstellt. Der Dienstanbieter übernimmt keine Gewähr für die
			 Richtigkeit, Vollständigkeit, Aktualität und Qualität der bereitgestellten Informationen. Bezug nehmend auf Schäden jeglicher
			 Art aufgrund der Nutzung oder Nichtnutzung der vorhandenen Informationen sind grundsätzlich Haftungsansprüche ausgeschlossen.
			 Liegt eine Rechtsverletzung vor, so ist eine Haftung erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
			 Die von Rechtsverletzung betroffenen Inhalte werden nach bekannt werden umgehend entfernt.
			 <br />
			 <b>Haftung für Links</b><br />
			 Der Dienstanbieter übernimmt keinerlei Gewähr für direkte und indirekte externe Links. Für die Inhalte der externen Links ist
			 immer der jeweilige Betreiber oder Anbieter dieser Seiten verantwortlich. Zum Zeitpunkt der Verlinkung wurden die Links überprüft
			 und keine Rechtsverstöße festgestellt. Eine permanente Prüfung der externen Links ist nicht zumutbar. Im Falle einer berechtigten
			 Feststellung der Rechtsverletzung erfolgt umgehend die Entfernung der entsprechenden Links.
			 <br />
			 <b>Urheberrecht</b><br />
			 Alle Inhalte der vorhandenen Seiten unterliegen dem Urheberrecht. Eine Nutzung des Inhalts oder jeglicher Teile davon ist nicht
			 zulässig, sondern bedarf der Zustimmung des Dienstanbieters. Dies gilt ebenso für die Urheberrechte Dritter. Bei Zuwiderhandlungen
			 behält sich der Dienstanbieter vor, Anzeige zu erstatten. Sollten trotz aller Vorsichtsmaßnahmen dennoch Urheberrechtsverletzungen
			 vorliegen, bitten wir um Mitteilung. Bei berechtigter Feststellung der Rechtsverletzung werden wir die entsprechenden Inhalte
			 unverzüglich entfernen.
			 <br />
			 <b>Streitbeilegung</b><br />
			 Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit.
			 <br />
			 <b>Verbraucherstreitbeilegungsgesetz</b><br />
			 Wir sind nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
			 <br />
			 </p>
	  </div>
	</Layout>
);

export default Impressum;
